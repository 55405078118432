const required = (field, fantom = false) => {
  if (fantom) {
    console.log("fantom check");
  } else field.isDirty = true;
  if (field?.noValidate) {
    field.errorBag = [];
    return;
  }
  if (typeof field.value === Array || typeof field.value === Object) {
    if (!field.value.length) {
      field.errorBag.unshift("Необходимо отметить поле");
    } else {
      field.errorBag = field.errorBag.filter(
        (item) => item != "Необходимо отметить поле"
      );
    }
    field.errorBag = [...new Set(field.errorBag)];
    return;
  } else {
    if (field.value == "" || field.length <= 0) {
      field.errorBag.unshift("Поле обязательно для заполнения");
    } else {
      field.errorBag = field.errorBag.filter(
        (item) => item != "Поле обязательно для заполнения"
      );
    }
    field.errorBag = [...new Set(field.errorBag)];
    return;
  }
};

const validateMinLength = (field, length, errorLength) => {
  field.isDirty = true;
  if (field?.noValidate) {
    field.errorBag = [];
    return;
  }
  field.errorBag = [...new Set(field.errorBag)];
  if (field.value.length < length) {
    field.errorBag.unshift(`Должен состоять из ${errorLength} символов`);
  } else {
    field.errorBag = field.errorBag.filter(
      (item) => item != `Должен состоять из ${errorLength} символов`
    );
  }
  field.errorBag = [...new Set(field.errorBag)];
};

const sameAs = (field, compare, key) => {
  field.isDirty = true;
  if (field?.noValidate) {
    field.errorBag = [];
    return true;
  }
  field.errorBag = [...new Set(field.errorBag)];
  if (typeof compare == "object") {
    let test = compare.filter(
      (item) => item?.[key]?.toLowerCase() === field.value?.toLowerCase()
    )?.[0];
    if (test == undefined) {
      field.errorBag.unshift(`Выберите адрес из списка`);
      return false;
    } else {
      field.errorBag = field.errorBag.filter(
        (item) => item != `Выберите адрес из списка`
      );
      return true;
    }
  }
  if (typeof compare == "string") {
    let test = field.value === compare;
    console.log(test);
  }
};

export { required, validateMinLength, sameAs };
