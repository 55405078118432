import { required, validateMinLength, sameAs } from "./validates.js";

const time = [
  { name: "На следующий день" },
  { name: "10:00" },
  { name: "10:30" },
  { name: "10:45" },
  { name: "11:00" },
  { name: "11:15" },
  { name: "11:30" },
  { name: "11:45" },
  { name: "12:00" },
  { name: "12:15" },
  { name: "12:30" },
  { name: "12:45" },
  { name: "13:00" },
  { name: "13:15" },
  { name: "13:30" },
  { name: "13:45" },
  { name: "14:00" },
  { name: "14:15" },
  { name: "14:30" },
  { name: "14:45" },
  { name: "15:00" },
  { name: "15:15" },
  { name: "15:30" },
  { name: "15:45" },
  { name: "16:00" },
  { name: "16:15" },
  { name: "16:30" },
  { name: "16:45" },
  { name: "17:00" },
  { name: "17:15" },
  { name: "17:30" },
  { name: "17:45" },
  { name: "18:00" },
  { name: "18:15" },
  { name: "18:30" },
  { name: "18:45" },
  { name: "19:00" },
  { name: "19:15" },
  { name: "19:30" },
  { name: "19:45" },
  { name: "20:00" },
  { name: "20:15" },
  { name: "20:30" },
  { name: "20:45" },
  { name: "21:00" },
  { name: "21:15" },
  { name: "21:30" },
  { name: "21:45" },
  { name: "22:00" },
];

const persons = [
  { name: "Кол-во персон (1)" },
  { name: "Кол-во персон (2)" },
  { name: "Кол-во персон (3)" },
  { name: "Кол-во персон (4)" },
  { name: "Кол-во персон (5)" },
  { name: "Кол-во персон (6)" },
  { name: "Кол-во персон (7)" },
  { name: "Кол-во персон (8)" },
  { name: "Кол-во персон (9)" },
];

const fields = {
  name: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "name",
      placeholder: "Введите имя",
      label: "Введите имя",
      regex: /\D/g,
      maska: {
        mask: "HHHHHHHHHHHHH",
        tokens: { H: { pattern: /[А-Яа-я ]/ } },
      },
      always_type: "text",
    },
    validate: {
      required: {
        fn: required,
        args: [],
      },
    },
  },
  phone: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "phone",
      placeholder: "Введите телефон",
      label: "Введите телефон",
      regex: /[0-9]/g,
      maska: {
        mask: ["+H (###) ###-##-##", "+H (###) ###-##-##"],
        tokens: { H: { pattern: /[78]/ } },
      },
      always_type: "text",
    },
    validate: {
      validateMinLength: {
        fn: validateMinLength,
        args: [18, 11],
      },
      required: {
        fn: required,
        args: [],
      },
    },
  },
  cutlery: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      label: "Без приборов",
      visible: false,
      id: "cutlery",
    },
    items: persons,
    validate: {},
  },
  time: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      label: "",
      visible: false,
      id: "time",
    },
    items: time,
    validate: {
      required: {
        fn: required,
        args: [],
      },
    },
  },
  pickup: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "pickup",
      label: "Самовывоз",
    },
    validate: {},
  },
  delivery: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "delivery",
      label: "Доставка по адресу",
    },
    validate: {},
  },
  soon_as_possible: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "soon_as_possible",
      label: "Ближайшее время",
    },
    validate: {},
  },
  pre_order: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "pre_order",
      label: "Предзаказ",
    },
    validate: {},
  },
  street: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "street",
      placeholder: "Улица",
      label: "Улица",
      maska: {
        mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
        tokens: { H: { pattern: /[0-9 а-яА-Яa-zA-Z./-]/ } },
      },
      always_type: "text",
      suggestions: {
        suggest: {},
        toggle: true,
        items: [],
        key: "address",
      },
    },
    validate: {
      required: {
        fn: required,
        args: [],
      },
      sameAs: {
        fn: sameAs,
        args: ["addresses"],
      },
    },
  },
  private_house: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "private_house",
      label: "Частный дом",
    },
    validate: {},
  },
  house: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "home",
      placeholder: "Дом",
      label: "Дом",
      maska: {
        mask: "HHHHHHH",
        tokens: { H: { pattern: /[А-Яа-я 0-9]/ } },
      },
      always_type: "text",
    },
    validate: {
      required: {
        fn: required,
        args: [],
      },
    },
  },
  apart: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "apart",
      placeholder: "Квартира",
      label: "Квартира",
      maska: {
        mask: "HHHH",
        tokens: { H: { pattern: /[0-9]/ } },
      },
      always_type: "text",
    },
    validate: {
      required: {
        fn: required,
        args: [],
      },
    },
  },
  entrance: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "entrance",
      placeholder: "Подъезд",
      label: "Подъезд",
      maska: {
        mask: "HH",
        tokens: { H: { pattern: /[0-9]/ } },
      },
      always_type: "text",
    },
    validate: {
      required: {
        fn: required,
        args: [],
      },
    },
  },
  floor: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "floor",
      placeholder: "Этаж",
      label: "Этаж",
      regex: /\D/g,
      maska: {
        mask: "HHH",
        tokens: { H: { pattern: /[0-9]/ } },
      },
      always_type: "text",
    },
    validate: {
      required: {
        fn: required,
        args: [],
      },
    },
  },
  comment: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "comment",
      placeholder: "Комментарий к заказу...",
      label: "Комментарий к заказу...",
      regex: /\D/g,
      maska: {
        mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
        tokens: { H: { pattern: /[А-Яа-я 0-9,./-—]/ } },
      },
      always_type: "text",
    },
    validate: {},
  },
  card: {
    value: "",
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "card",
      label: "Безналичными",
    },
    errorBag: [],
    validate: {},
  },
  cash: {
    value: "",
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "cash",
      label: "Наличными",
    },
    errorBag: [],
    validate: {},
  },
  online: {
    value: "",
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "online",
      label: "Оплатить онлайн",
    },
    errorBag: [],
    validate: {},
  },
  offline: {
    value: "",
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "offline",
      label: "При получении",
    },
    errorBag: [],
    validate: {},
  },
  payment_type: {
    value: "Безнал",
    isDirty: false,
    noValidate: true,
    errorBag: [],
    validate: {
      required: {
        fn: required,
        args: [],
      },
    },
  },
  type: {
    value: "Доставка",
    isDirty: false,
    noValidate: true,
    errorBag: [],
    validate: {},
  },
  expected_delivery_time: {
    value: "Ближайшее время",
    isDirty: false,
    noValidate: true,
    errorBag: [],
    validate: {},
  },
  promocode: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "promocode",
      placeholder: "Промокод",
      label: "Промокод",
      maska: {
        mask: "HHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",
        tokens: { H: { pattern: /[A-Za-zА-Яа-я 0-9]/ } },
      },
      always_type: "text",
    },
    validate: {},
  },
  affilate: {
    value: "",
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "affilate",
      label: "Филиал",
    },
    errorBag: [],
    validate: {},
  },
  affilate_name: {
    value: "",
    isDirty: false,
    noValidate: true,
    attrs: {
      id: "affilate_name",
      label: "Адрес филиала",
    },
    errorBag: [],
    validate: {},
  },
};

export { fields };
