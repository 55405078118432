<template>
  <div class="v-cart-list">
    <v-empty v-show="!cart_data.length">Корзина пуста</v-empty>
    <transition-group name="fade" mode="out-in">
      <v-cart-item
        v-for="item in cart_data"
        :key="item.id"
        :cart_item_data="item"
        @increment="increment"
        @decrement="decrement"
        @toggleSize="toggleSize"
      ></v-cart-item>
    </transition-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vCartItem from "./v-cart-item.vue";
export default {
  name: "v-cart-list",
  components: {
    vCartItem,
  },
  props: {
    cart_data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions(["INCREMENT", "DECREMENT", "TOGGLE_SIZE_OF_PIZZA"]),
    increment(data) {
      this.INCREMENT(data);
      this.$emit("reCheckPromo");
      this.$emit("reValidateAddress");
    },
    decrement(data) {
      this.DECREMENT(data);
      this.$emit("reCheckPromo");
      this.$emit("reValidateAddress");
    },
    toggleSize(data) {
      this.TOGGLE_SIZE_OF_PIZZA(data);
      this.$emit("reCheckPromo");
      this.$emit("reValidateAddress");
    },
  },
  computed: {
    ...mapGetters([""]),
  },
};
</script>
<style lang="scss">
// .v-cart-list

.v-cart-list {
  .cart__item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
