const tariffs = [
  {
    delivery_free: 600,
    delivery_zone: 1,
  },
  {
    delivery_free: 800,
    delivery_zone: 2,
  },
  {
    delivery_free: 1000,
    delivery_zone: 3,
  },
  {
    delivery_free: 1200,
    delivery_zone: 4,
  },
  {
    delivery_free: 1400,
    delivery_zone: 5,
  },
  {
    delivery_free: 1800,
    delivery_zone: 6,
  },
  {
    delivery_free: 2500,
    delivery_zone: 7,
  },
];

export { tariffs };
