<template>
  <section class="cart">
    <v-back-menu class="cart__back">
      <v-back-btn v-if="this.confirm_order == false" :to="`/`"></v-back-btn>

      <button
        class="cart-back-btn"
        v-if="this.confirm_order == true"
        @click.prevent="this.confirm_order = false"
      >
        <v-icon name="back-icon"></v-icon>
      </button>

      <h2 class="cart__back-title">
        {{ confirm_order == false ? "Ваш заказ" : "Продолжаем оформление" }}
      </h2></v-back-menu
    >

    <v-cart-list
      @reCheckPromo="checkPromocode"
      @reValidateAddress="validateAddress('emit')"
      class="cart__data"
      :cart_data="CART"
    />

    <div class="cart__confirm">
      <transition name="confirm" mode="out-in">
        <form action="" class="cart__order order__form">
          <v-input
            class="order__form-input"
            :attrs="order_form.fields.name.attrs"
            :errorBag="order_form.fields.name.errorBag"
            v-model="order_form.fields.name.value"
            ref="order_name"
            @focusout="
              order_form.fields.name.validate.required.fn(
                this.order_form.fields.name
              )
            "
          />
          <v-input
            class="order__form-input"
            :attrs="order_form.fields.phone.attrs"
            :errorBag="order_form.fields.phone.errorBag"
            v-model="order_form.fields.phone.value"
            ref="order_phone"
            @focusout="
              order_form.fields.phone.validate.validateMinLength.fn(
                this.order_form.fields.phone,
                18,
                11
              ),
                order_form.fields.phone.validate.required.fn(
                  this.order_form.fields.phone
                )
            "
          />
          <v-select
            @openSelect="openSelect"
            @closeSelect="closeSelect"
            @selectOption="selectOption"
            :data="order_form.fields.cutlery.attrs"
            :items="order_form.fields.cutlery.items"
            class="order__form-select cutlery"
            v-model="order_form.fields.cutlery.value"
            :errorBag="order_form.fields.cutlery.errorBag"
            id="cutlery"
          />

          <v-radio
            class="order__form-input"
            value="Самовывоз"
            :attrs="order_form.fields.pickup.attrs"
            v-model="order_form.fields.type.value"
          >
            <template v-slot:icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9ZM9.5 9C9.5 7.61929 10.6193 6.5 12 6.5C12.8932 6.5 13.7185 6.9765 14.1651 7.75C14.6116 8.5235 14.6116 9.4765 14.1651 10.25C13.7185 11.0235 12.8932 11.5 12 11.5C10.6193 11.5 9.5 10.3807 9.5 9Z"
                  fill="#757575"
                />
              </svg>
            </template>
            <template v-slot:append> -10% </template>
          </v-radio>
          <v-radio
            class="order__form-input"
            value="Доставка"
            :attrs="order_form.fields.delivery.attrs"
            v-model="order_form.fields.type.value"
          >
            <template v-slot:icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.92 5.01C18.72 4.42 18.16 4 17.5 4H6.5C5.84 4 5.29 4.42 5.08 5.01L3 11V19C3 19.55 3.45 20 4 20H5C5.55 20 6 19.55 6 19V18H18V19C18 19.55 18.45 20 19 20H20C20.55 20 21 19.55 21 19V11L18.92 5.01ZM6.85 6H17.14L18.22 9.11H5.77L6.85 6ZM5 16H19V11H5V16ZM7.5 12C6.67157 12 6 12.6716 6 13.5C6 14.3284 6.67157 15 7.5 15C8.32843 15 9 14.3284 9 13.5C9 12.6716 8.32843 12 7.5 12ZM15 13.5C15 12.6716 15.6716 12 16.5 12C17.3284 12 18 12.6716 18 13.5C18 14.3284 17.3284 15 16.5 15C15.6716 15 15 14.3284 15 13.5Z"
                  fill="#757575"
                />
              </svg>
            </template>
          </v-radio>
          <v-radio
            class="order__form-input"
            value="Ближайшее время"
            :attrs="order_form.fields.soon_as_possible.attrs"
            v-model="order_form.fields.expected_delivery_time.value"
            :disabled="
              working_time_data?.minutesAfterOpen < 0 ||
              working_time_data?.minutesUntilClose < 30
            "
          >
            <template v-slot:icon> </template>
            <template v-slot:append> </template>
          </v-radio>
          <v-radio
            class="order__form-input"
            value="Предзаказ"
            :attrs="order_form.fields.pre_order.attrs"
            v-model="order_form.fields.expected_delivery_time.value"
          >
            <template v-slot:icon> </template>
          </v-radio>
          <transition
            appear
            @before-enter="beforeEnter"
            @enter="enter"
            :css="false"
          >
            <v-select
              @openSelect="openSelect"
              @closeSelect="closeSelect"
              @selectOption="selectOption"
              :data="order_form.fields.time.attrs"
              :items="this.pickupTime"
              class="order__form-select time"
              v-model="order_form.fields.time.value"
              :errorBag="order_form.fields.time.errorBag"
              v-show="
                order_form.fields.expected_delivery_time.value !==
                'Ближайшее время'
              "
              ref="order_time"
            />
          </transition>
          <transition
            appear
            @before-enter="beforeEnter"
            @enter="enter"
            :css="false"
          >
            <div
              class="order__form-wrapper"
              v-show="order_form.fields.type.value == 'Доставка'"
              ref="delivery_wrapper"
            >
              <v-radio
                class="order__form-input"
                value="Частный дом"
                :attrs="order_form.fields.private_house.attrs"
                v-model="order_form.fields.private_house.value"
                @click="selectPrivateHouse"
              ></v-radio>
              <v-input
                class="order__form-input"
                :attrs="order_form.fields.street.attrs"
                :errorBag="order_form.fields.street.errorBag"
                v-model:modelValue="order_form.fields.street.value"
                v-model:suggestValue="
                  order_form.fields.street.attrs.suggestions.suggest
                "
                ref="order_street"
                @selectSuggestion="selectAddress(this.order_form.fields.street)"
              />
              <v-input
                class="order__form-input"
                :attrs="order_form.fields.house.attrs"
                :errorBag="order_form.fields.house.errorBag"
                v-model="order_form.fields.house.value"
                ref="order_house"
                @focusout="
                  order_form.fields.house.validate.required.fn(
                    this.order_form.fields.house
                  ),
                    validateAddress()
                "
              />
              <v-input
                class="order__form-input"
                :attrs="order_form.fields.apart.attrs"
                :errorBag="order_form.fields.apart.errorBag"
                v-model="order_form.fields.apart.value"
                ref="order_apart"
                @focusout="
                  order_form.fields.apart.validate.required.fn(
                    this.order_form.fields.apart
                  )
                "
              />
              <v-input
                class="order__form-input"
                :attrs="order_form.fields.entrance.attrs"
                :errorBag="order_form.fields.entrance.errorBag"
                v-model="order_form.fields.entrance.value"
                ref="order_entrance"
                @focusout="
                  order_form.fields.entrance.validate.required.fn(
                    this.order_form.fields.entrance
                  )
                "
              />
              <v-input
                class="order__form-input"
                :attrs="order_form.fields.floor.attrs"
                :errorBag="order_form.fields.floor.errorBag"
                v-model="order_form.fields.floor.value"
                ref="order_floor"
                @focusout="
                  order_form.fields.floor.validate.required.fn(
                    this.order_form.fields.floor
                  )
                "
              />
            </div>
          </transition>

          <v-radio
            class="order__form-input"
            value="Безнал"
            :attrs="order_form.fields.card.attrs"
            v-model="order_form.fields.payment_type.value"
          >
            <template v-slot:icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM4 8H20V6H4V8Z"
                  fill="#757575"
                />
              </svg>
            </template>
          </v-radio>
          <v-radio
            class="order__form-input"
            value="Нал"
            :attrs="order_form.fields.cash.attrs"
            v-model="order_form.fields.payment_type.value"
          >
            <template v-slot:icon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.5 5V7.28C21.09 7.63 21.5 8.26 21.5 9V15C21.5 15.74 21.09 16.37 20.5 16.72V19C20.5 20.1 19.6 21 18.5 21H4.5C3.39 21 2.5 20.1 2.5 19V5C2.5 3.9 3.39 3 4.5 3H18.5C19.6 3 20.5 3.9 20.5 5ZM12.5 15H19.5V9H12.5V15ZM4.5 19V5H18.5V7H12.5C11.4 7 10.5 7.9 10.5 9V15C10.5 16.1 11.4 17 12.5 17H18.5V19H4.5ZM14 12C14 11.1716 14.6716 10.5 15.5 10.5C16.3284 10.5 17 11.1716 17 12C17 12.8284 16.3284 13.5 15.5 13.5C14.6716 13.5 14 12.8284 14 12Z"
                  fill="#757575"
                />
              </svg>
            </template>
          </v-radio>
          <!-- <v-radio
            value="Оплатить онлайн"
            :attrs="order_form.fields.online.attrs"
            v-model="order_form.fields.payment.value"
          />
          <v-radio
            value="При получении"
            :attrs="order_form.fields.offline.attrs"
            v-model="order_form.fields.payment.value"
          /> -->
          <v-input
            class="order__form-input"
            :attrs="order_form.fields.comment.attrs"
            :errorBag="order_form.fields.comment.errorBag"
            v-model="order_form.fields.comment.value"
            ref="order_comment"
          />
        </form>
      </transition>

      <div
        class="cart-info"
        :class="{ 'cart-info--mobile': this.confirm_order == true }"
        id="cart-info"
      >
        <v-input
          class="cart-info__input"
          :attrs="order_form.fields.promocode.attrs"
          :errorBag="order_form.fields.promocode.errorBag"
          v-model="order_form.fields.promocode.value"
          ref="order_promocode"
          @focusout="checkPromocode"
        >
          <template #append>
            <div class="cart-info__input-icon">%</div>
          </template>
        </v-input>
        <div class="cart-info__wrapper">
          <div class="cart-info__list">
            <div class="cart-info__item">
              <p class="cart-info__description">Промежуточный итог</p>
              <p class="cart-info__description">{{ rub(SUBTOTAL) }} ₽</p>
            </div>

            <div class="cart-info__item">
              <p class="cart-info__description">Промокод</p>
              <p class="cart-info__description">
                {{ rub(this.active_promo?.output) }}
                {{
                  this.active_promo?.type == "число"
                    ? " ₽"
                    : this.active_promo?.type == "процент"
                    ? " %"
                    : this.active_promo?.type == "продукт"
                    ? ""
                    : "0 ₽"
                }}
              </p>
            </div>

            <div class="cart-info__item">
              <p class="cart-info__description">
                {{ this.order_form.fields.type.value }}
              </p>
              <p class="cart-info__description">
                {{ rub(this.deliveryPay) }} ₽
              </p>
            </div>

            <div class="cart-info__item">
              <p class="cart-info__description" ref="order_warning">
                {{ cart_info.warning }}
              </p>
            </div>

            <div class="cart-info__item">
              <p class="cart-info__description" ref="order_error">
                {{ cart_info.error }}
              </p>
            </div>
          </div>

          <p class="cart-info__description cart-info__dashes">
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          </p>

          <router-link class="cart-info__policy" to="/privacy">
            Политика конфиденциальности
          </router-link>
          <button
            class="send-form-btn"
            @click.prevent="callToAffilate"
            ref="sendOrderBtn"
          >
            <div>
              <p>
                {{ this.confirm_order == false ? `Оформить` : `Оформить` }}
              </p>
              <p>
                {{ rub(this.total) }}
                ₽
              </p>
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 12H20"
                stroke="#6B6B6B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 6L20 12L14 18"
                stroke="#6B6B6B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <v-popup :data="affilate_dialog" @closePopup="closePopup">
        <h3>{{ affilate_dialog.title }}</h3>
        <div class="affilate__row">
          <v-btn
            class="affilate__btn"
            @click="sendOrder(affilate)"
            :disabled="order_disabled"
            :accent="true"
            v-for="(affilate, idx) in filtred_affilates"
            :key="idx"
          >
            {{ affilate?.affilate_name }}
          </v-btn>
        </div>
      </v-popup>
    </transition>
  </section>
</template>
<script>
import vCartList from "../../components/dev/cart/v-cart-list.vue";
import { mapActions, mapGetters } from "vuex";
import focusMixin from "@/mixins/focusMixin";
import { rub } from "../../helpers/converters";
import { sortArray } from "../../helpers/utils/sortArray";
import gsap from "gsap";
import { fadeFilledAnimate } from "../../helpers/animations.js";
import { fields } from "../../helpers/cart_data.js";
import apiRequest from "../../store/api-request";
import { tariffs } from "../../helpers/addresses_tariffs.js";
export default {
  name: "v-cart-view",
  components: {
    vCartList,
  },
  mixins: [focusMixin],
  data() {
    return {
      tariffs,
      confirm_order: false,
      order_disabled: false,
      order_form: {
        valid: false,
        fields,
      },
      cart_info: {
        error: "",
        warning: "",
      },
      active_promo: {},
      clone_order_form: {},
      affilate_dialog: {
        options: {
          visible: false,
          closeBtn: false,
        },
        title: "Выберите точку самовывоза",
      },
    };
  },
  methods: {
    rub,
    selectPrivateHouse() {
      const btn = this.order_form.fields.private_house;
      if (btn?.value?.length) {
        setTimeout(() => {
          btn.value = "";
        }, 1);
      }
    },
    test() {
      console.log("eet");
    },
    closePopup(e) {
      if (
        e.target.classList.contains("popup") ||
        e.target.classList.contains("popup__close")
      ) {
        this.affilate_dialog.options.visible = false;
      }
    },
    ...mapActions([
      "SET_SAVED_CART",
      "GET_ADRESSES",
      "GET_PROMOCODES",
      "GLOBAL_NOTIFICATIONS",
      "SEND_ORDER",
      "GET_AFFILATES",
    ]),
    async checkPromocode(val, total) {
      try {
        // this.active_promo = {};
        total = +this.SUBTOTAL;
        val = this.order_form.fields.promocode.value;
        this.$store.state.cart = this.$store.state.cart.filter(
          (item) =>
            item.promotion !== "Акционный товар" ||
            item?.category_name?.toLowerCase() !== "акционные блюда"
        );
        if (val?.length <= 0) return;
        if (this.order_form.fields.type.value == "Самовывоз") {
          throw {
            title: "Недопустимый тип заказа",
            text: "Промокоды не действуют при самовывозе",
            status: "error",
            clean: true,
          };
        }
        const promocode = Array.from(this.PROMOCODES)?.filter(
          (item) => item.name?.toLowerCase() == val?.toLowerCase()
        )?.[0];
        if (promocode?.conditions == undefined) {
          throw {
            title: "Промокод не найден",
            text: "Попробуйте ввести другой!",
            status: "error",
            clean: true,
          };
        }
        const { price, time, products } = promocode.conditions;

        sortArray;

        if (price !== undefined) {
          if (total < price) {
            this.cart_info.error = `Для применения промокода закажите ещё на ${rub(
              price - total
            )} ₽`;
            throw {
              title: `Для применения промокода закажите ещё на ${rub(
                price - total
              )} ₽`,
              status: "error",
              clean: true,
            };
          }
        }
        if (time !== undefined) {
          let currHour = new Date().getHours();
          if (currHour < time?.start || currHour > time?.end) {
            this.cart_info.error = `Промокод работает с ${time?.start}:00 до ${time?.end}:00`;
            throw {
              title: `Промокод работает с ${time?.start}:00 до ${time?.end}:00`,
              status: "error",
              clean: true,
            };
          }
        }
        console.log(products);
        if (products !== undefined) {
          let hasProducts = false;
          for (const product of products) {
            if (
              Array.from(this.CART).filter((item) => item.name == product)
                .length
            ) {
              hasProducts = true;
            }
          }
          if (hasProducts) {
            this.cart_info.error = ``;
          } else {
            this.cart_info.error =
              `Для применения промокода добавьте${products.map(
                (item) => " " + item
              )}`.replace(/[,]/g, " или ");
            throw {
              title: `Для применения промокода добавьте${products.map(
                (item) => " " + item
              )}`.replace(/[,]/g, " или "),
              status: "error",
              clean: true,
            };
          }
        }
        this.promocodeController("apply", promocode);
      } catch (error) {
        if (error.clean) {
          this.cart_info.error = ``;
          this.cart_info.warning = ``;
        }
        this.active_promo = {};
        this.cleanCartOfPromotionProducts();
        if (error?.title) {
          this.GLOBAL_NOTIFICATIONS(error);
          this.order_form.fields.promocode.value = "";
        }
        console.log(error);
        return;
      }
    },
    cleanCartOfPromotionProducts() {
      this.$store.state.cart = this.$store.state.cart.filter(
        (item) => item?.promotion !== "Акционный товар"
      );
    },
    async applyPromocode(promo) {
      let promotion = JSON.parse(JSON.stringify(promo));

      console.log(promotion);
      if (promotion.type == "число") {
        promotion.output = promotion.discount;
      }
      if (promotion.type == "процент") {
        promotion.output = promotion.discount;
      }
      if (promotion.type == "продукт") {
        let product = await this.getDiscountProduct(promotion.discount);
        this.$store.dispatch("ADD_TO_CART", product);
        promotion.price = product.price;
        promotion.output = `${product?.name} ${product?.price} ₽` || "";
      }

      this.active_promo = promotion;
    },
    async getDiscountProduct(promotionProduct) {
      return await apiRequest.function(
        "GET",
        "/getPromotionProduct",
        [],
        promotionProduct
      );
    },
    async promocodeController(action, data) {
      if (action == "apply") {
        // this.cart_info.warning = "";
        // this.cart_info.error = "";
        this.applyPromocode(data);
      }
      if (action == "check") {
        this.checkPromocode();
      }
    },
    async validateForm() {
      return new Promise((resolve, reject) => {
        try {
          Object.keys(this.order_form.fields).map((item) => {
            Object.values(this.order_form.fields[item]?.validate)?.map((el) => {
              if (el?.args?.[0] == "addresses") {
                el.fn(this.order_form.fields.street, this.ADRESSES, "address");
              } else el?.fn(this.order_form.fields[item], ...el?.args);
            });
          });
          const is_address_valid = this.validateAddress("form");
          const is_time_valid = this.validateTime("form");
          console.log(is_address_valid, is_time_valid);
          if (this.orderFormValid && is_address_valid && is_time_valid) {
            resolve(true);
          } else {
            reject(false);
          }
        } catch (error) {
          console.log("eqwe", error);
          reject(false);
        }
      });
    },
    async sendOrder(affilate) {
      this.order_disabled = true;
      this.order_form.fields.affilate.value = affilate?.affilate_id || "";
      this.order_form.fields.affilate_name.value =
        affilate?.affilate_name || "";
      this.order_form.fields.promocode.value = this?.activePromo?.name || "";
      if (affilate?.is_open === "Закрыто" || !affilate?.pickup_access?.length) {
        this.GLOBAL_NOTIFICATIONS({
          title:
            "К сожалению филиал закрыт по техническим причинам до конца дня",
          text: "Попробуйте выбрать другой адрес доставки или самовывоза",
          status: "error",
        });
        this.order_disabled = false;
        return;
      }

      const payload = {
        form: Object.assign({}, this.order_form.fields),
        fd_products: this.CART.map((item) => item.id),
        fd_count: this.CART.map((item) => item.count),
        products: this.CART,
        delivery_pay: this.deliveryPay,
        total: this.total,
      };

      this.SEND_ORDER(payload)
        .then((order_resp) => {
          console.log(order_resp?.response);
          if (order_resp?.status === 200) {
            this.$store.state.order = {
              phone: this.order_form.fields.phone.value,
              type: this.order_form.fields.type.value,
            };
            this.$router.push("/thx");
          } else {
            this.GLOBAL_NOTIFICATIONS(order_resp?.response?.data);
          }
        })
        .finally(() => {
          this.order_disabled = false;
        });
    },
    async callToAffilate() {
      try {
        await this.validateForm().then((is_valid) => {
          this.order_form.fields.promocode.value =
            this?.activePromo?.name || "";
          this.order_form.fields.affilate.value =
            this.address_affilate_coincidence?.affilate || "";
          const payload = {
            form: Object.assign({}, this.order_form.fields),
            fd_products: this.CART.map((item) => item.id),
            fd_count: this.CART.map((item) => item.count),
            products: this.CART,
            delivery_pay: this.deliveryPay,
            total: this.total,
          };

          if (is_valid) {
            if (this.order_form.fields.type.value == "Самовывоз")
              this.affilate_dialog.options.visible = true;
            else
              this.SEND_ORDER(payload).then((order_resp) => {
                if (order_resp?.status === 200) {
                  this.$store.state.order = {
                    phone: this.order_form.fields.phone.value,
                    type: this.order_form.fields.type.value,
                  };
                  this.$router.push("/thx");
                } else {
                  this.GLOBAL_NOTIFICATIONS(order_resp?.response?.data);
                }
              });
          }
        });
      } catch (error) {
        console.log("err", error);
      }
    },
    openSelect(field) {
      field.visible = true;
      return;
    },
    closeSelect(field) {
      field.visible = false;
      return;
    },
    selectOption({ option, field }) {
      console.log(option, field);
      this.order_form.fields[field].value = option.name;
      return;
    },
    beforeEnter(el) {
      gsap.to(el, { opacity: 0, duration: 0.2, transition: 0.3 });
    },
    enter(el) {
      gsap.to(el, {
        opacity: 1,
        duration: 0.2,
        transition: 0.3,
      });
    },
    validateAddress() {
      let min_delivery_sum_valid = false,
        subtotal_delivery_valid = false,
        same_as_valid = false;
      const subtotal = +this.SUBTOTAL,
        { street, type, house } = this.order_form.fields;
      const all_notifications = this.NOTIFICATIONS;
      const affilate = this.AFFILATES.filter(
        (item) =>
          item.affilate_id === this.address_affilate_coincidence?.affilate + ""
      )?.[0];
      same_as_valid;
      subtotal_delivery_valid;
      min_delivery_sum_valid;
      street;
      all_notifications;

      same_as_valid = this.order_form.fields.street.validate.sameAs.fn(
        this.order_form.fields.street,
        this.ADRESSES,
        "address"
      );

      if (!same_as_valid) {
        return false;
      }

      const { delivery_free, zone } = this.address_delivery_zone_coincidence;

      if (zone?.delivery_zone === 7) {
        if (
          !all_notifications?.filter(
            (item) =>
              item.title ===
              "Вы находитесь слишком далеко, мы можем не принять ваш заказ из-за высокой нагрузки или погодных условий"
          )?.length
        ) {
          this.GLOBAL_NOTIFICATIONS({
            title: `Вы находитесь слишком далеко, мы можем не принять ваш заказ из-за высокой нагрузки или погодных условий`,
            text: "Подробности у операторов 2390012 Казань, 2900012 Дербышки",
            status: "warning",
          });
        }
      }

      if (subtotal < +delivery_free && type.value == "Доставка") {
        if (
          !all_notifications?.filter((item) =>
            item.title?.includes("Закажите ещё на")
          )?.length
        ) {
          this.GLOBAL_NOTIFICATIONS({
            title: `Закажите ещё на ${rub(
              delivery_free - subtotal
            )} ₽ для оформления доставки`,
            text: "Если вашего дома или улицы нет в списке , выберете ближайшую и сообщите об этом оператору при подтверждении заказа",
            status: "error",
          });
        }

        this.cart_info.error = `Закажите ещё на ${rub(
          delivery_free - subtotal
        )} ₽ для оформления доставки`;
        return false;
      }
      if (
        zone === undefined &&
        type.value == "Доставка" &&
        this.order_form.fields.house.isDirty
      ) {
        if (
          !all_notifications?.filter(
            (item) => item.title === "Выбранный дом не найден!"
          )?.length
        ) {
          this.GLOBAL_NOTIFICATIONS({
            title: "Выбранный дом не найден!",
            text: "Выберите ближайший дом и сообщите об этом оператору",
            status: "error",
          });
        }

        this.cart_info.error = `Выбранного дома нет в списке, выберите ближайший дом и сообщите об этом оператору`;
        return false;
      }
      if (type.value === "Доставка" && house.value?.length) {
        if (
          affilate?.is_open === "Закрыто" ||
          affilate.delivery_access !== "delivery_access"
        ) {
          this.GLOBAL_NOTIFICATIONS({
            title:
              "К сожалению филиал который доставляет по вашему адресу закрыт по техническим причинам до конца дня",
            text: "Попробуйте выбрать другой адрес доставки или самовывоза",
            status: "error",
          });
          this.cart_info.error = `К сожалению филиал который доставляет по вашему адресу закрыт по техническим причинам до конца дня`;
          return false;
        }
      }

      this.cart_info.error = "";
      // if (subtotal < +free && type.value == "Доставка") {
      //   this.cart_info.warning = `Закажите ещё на ${rub(
      //     +free - subtotal
      //   )} ₽ для бесплатной доставки`;
      // } else {
      //   this.cart_info.warning = "";
      // }
      return true;
    },
    validateTime() {
      // const now = new Date();
      // let currentTime = now.getUTCHours() + 3 + ":" + now.getUTCMinutes();
      // const {
      //   expected_delivery_time: { value: deliveryTime } = {},
      //   time: { value: orderTime } = {},
      //   type: { value: orderType } = {},
      // } = this.order_form.fields || {};

      // const { curr_day: { day_start: dayStart, day_end: dayEnd } = {} } =
      //   this.SITE_SETTINGS;

      // const minutesUntilClose =
      //   this.getFutureDate(dayEnd) -
      //   this.getFutureDate(
      //     orderTime === "На следующий день" ? currentTime : orderTime
      //   );

      // const minutesAfterOpen =
      //   this.getFutureDate(currentTime) - this.getFutureDate(dayStart);

      // if (minutesUntilClose < 30) {
      //   this.GLOBAL_NOTIFICATIONS({
      //     title: "К сожалению мы уже не принимаем заказы",
      //     text: "Вы можете оставить предзаказ",
      //     status: "error",
      //   });
      //   return false;
      // }

      // if (deliveryTime === "Предзаказ" || deliveryTime === "Ближайшее время")
      //   return true;
      // if (orderType === "Доставка" && minutesAfterOpen > 90) return true;
      // if (orderType === "Самовывоз" && minutesAfterOpen > 30) return true;

      return true;
    },
    getFutureDate(time, now = false) {
      const [hour, minute] = time?.split(":")?.map(Number) || [0, 0];
      let date = new Date();
      date.setHours(hour, minute, 0, 0);

      if (now && date < new Date()) {
        date.setDate(date.getDate() + 1);
      }

      return Math.floor(date.getTime() / 1000 / 60);
    },
    selectAddress(field) {
      if (
        field.value?.toLowerCase() !==
        field.attrs.suggestions.suggest?.address?.toLowerCase()
      ) {
        field.attrs.suggestions.suggest = {};
      }

      const {
        address = "",
        affilate = 32,
        delivery_pay = 200,
        delivery_free = 600,
      } = field.attrs?.suggestions?.suggest || {};
      affilate;
      delivery_pay;
      address;
      delivery_free;
      this.$nextTick(() => {
        let test = this.validateAddress("select");
        console.log(test);
      });
    },
    toggleOrderType(type) {
      this.cart_info.error = "";
      this.cart_info.warning = "";
      this.order_form.fields.time.value =
        this.pickupTime?.[1]?.name || this.pickupTime?.[0]?.name;
      if (type == "Самовывоз") {
        this.order_form.fields.street.noValidate = true;
        this.order_form.fields.house.noValidate = true;
        this.order_form.fields.apart.noValidate = true;
        this.order_form.fields.entrance.noValidate = true;
        this.order_form.fields.floor.noValidate = true;
        this.order_form.fields.street.attrs.suggestions.suggest = {};
        this.order_form.fields.street.value = "";
        this.order_form.fields.house.value = "";
        this.order_form.fields.apart.value = "";
        this.order_form.fields.entrance.value = "";
        this.order_form.fields.floor.value = "";
        this.order_form.fields.affilate.value = "";
        this.order_form.fields.private_house.value = "";
        this.order_form.fields.street.errorBag = [];
        this.order_form.fields.house.errorBag = [];
        this.order_form.fields.apart.errorBag = [];
        this.order_form.fields.entrance.errorBag = [];
        this.order_form.fields.floor.errorBag = [];
      }
      if (type == "Доставка") {
        this.order_form.fields.street.noValidate = false;
        this.order_form.fields.house.noValidate = false;
        this.order_form.fields.apart.noValidate = false;
        this.order_form.fields.entrance.noValidate = false;
        this.order_form.fields.floor.noValidate = false;
        this.order_form.fields.affilate.value = "";
      }
    },
  },
  computed: {
    ...mapGetters([
      "CART",
      "SUBTOTAL",
      "ADRESSES",
      "PROMOCODES",
      "AFFILATES",
      "NOTIFICATIONS",
      "SITE_SETTINGS",
    ]),
    total() {
      if (!this.activePromo?.type) {
        return +this.SUBTOTAL + +this.deliveryPay;
      }
      if (this.activePromo?.type == "число") {
        return +this.SUBTOTAL - +this.activePromo?.discount + +this.deliveryPay;
      } else if (this.activePromo?.type == "процент") {
        return (
          +this.SUBTOTAL -
          (+this.SUBTOTAL * +this.activePromo?.discount) / 100 +
          +this.deliveryPay
        );
      } else if (this.activePromo?.type == "продукт") {
        return +this.SUBTOTAL + +this.deliveryPay + +this.activePromo?.price;
      } else {
        return 0;
      }
    },
    deliveryPay() {
      if (this.order_form.fields.type.value == "Доставка") {
        const delivery_free =
          this.address_delivery_zone_coincidence?.delivery_free;
        if (+this.SUBTOTAL < 600 || +this.SUBTOTAL < delivery_free)
          return (
            this.order_form.fields.street.attrs.suggestions?.suggest
              ?.delivery_pay || 0
          );
        else return 0;
      } else {
        return -(+this.SUBTOTAL * 10) / 100;
      }
    },
    activePromo() {
      return this.active_promo;
    },
    orderFormValid() {
      let errors = Object.values(this.order_form.fields).filter(
        (item) => (item.errorBag.length || !item.isDirty) && !item.noValidate
      );
      if (
        errors.length ||
        this.cart_info?.error?.length > 2 ||
        !this.CART.length
      ) {
        return false;
      } else {
        return true;
      }
    },
    pickupTime() {
      let pickupTimes = JSON.parse(
        JSON.stringify(this.order_form.fields.time.items)
      );
      let { day_start } = this.SITE_SETTINGS?.curr_day;
      let { expected_delivery_time, type } = this.order_form.fields;
      let date = new Date();
      let currTime = `${date.getUTCHours() + 3}:${date.getUTCMinutes()}`;
      let hour = currTime?.split(":")?.[0];
      let min = currTime?.split(":")?.[1];
      let interval =
        type.value === "Доставка" &&
        expected_delivery_time.value === "Предзаказ"
          ? 120
          : 120;
      let allTimeInMinutes = +hour * 60 + +min;
      let startWorkTimeInHours = day_start?.split(":")?.[0];
      let filtred = pickupTimes.filter((item) => {
        let pickupHour = +item.name?.split(":")?.[0];
        let pickupMin = +item.name?.split(":")?.[1];
        let allPickupTime = pickupHour * 60 + pickupMin;
        if (
          (pickupHour > +startWorkTimeInHours &&
            allPickupTime - allTimeInMinutes >= interval) ||
          item.name === "На следующий день"
        ) {
          return item;
        }
      });
      if (filtred.length) {
        return filtred;
      } else {
        return this?.clone_order_form?.fields?.time?.items;
      }
    },
    order_time_valid() {
      let date = new Date();
      let currTime = `${date.getUTCHours() + 3}:${date.getUTCMinutes()}`;
      let hour = currTime?.split(":")?.[0];
      let min = currTime?.split(":")?.[1];
      let time = this.order_form.fields.time.value;
      hour;
      min;
      return { time };
    },
    curr_address_conditions() {
      const {
        affilate_house_orenburgskiy = "",
        affilate_house_derbishki = "",
        affilate_house_kvartal = "",
        default_zone = "",
        default_affilate = "",
        need_verify_house,
      } = this.order_form.fields.street.attrs?.suggestions?.suggest || {};
      let output = {};

      output.default_zone = default_zone;
      output.default_affilate = default_affilate;

      affilate_house_orenburgskiy,
        affilate_house_derbishki,
        affilate_house_kvartal,
        default_affilate;
      default_zone;
      need_verify_house;
      output;
      return output;
    },
    address_delivery_zone_coincidence() {
      const {
        delivery_zona_1 = null,
        delivery_zona_2 = null,
        delivery_zona_3 = null,
        delivery_zona_4 = null,
        delivery_zona_5 = null,
        delivery_zona_6 = null,
        delivery_zona_7 = null,
      } = this.order_form.fields.street.attrs?.suggestions?.suggest || {};
      const house_inp = this.order_form.fields.house.value;
      let zones = [
        {
          houses: delivery_zona_1?.split(",") || [],
          delivery_zone: 1,
        },
        {
          houses: delivery_zona_2?.split(",") || [],
          delivery_zone: 2,
        },
        {
          houses: delivery_zona_3?.split(",") || [],
          delivery_zone: 3,
        },
        {
          houses: delivery_zona_4?.split(",") || [],
          delivery_zone: 4,
        },
        {
          houses: delivery_zona_5?.split(",") || [],
          delivery_zone: 5,
        },
        {
          houses: delivery_zona_6?.split(",") || [],
          delivery_zone: 6,
        },
        {
          houses: delivery_zona_7?.split(",") || [],
          delivery_zone: 7,
        },
      ];
      house_inp;
      // let coincidence_zone = {};
      let conditions = {};
      conditions = zones.filter(
        (item) => item.houses.filter((house) => house === house_inp)?.length
      )?.[0] || { delivery_zone: this.curr_address_conditions?.default_zone };
      let delivery_free =
        tariffs?.filter(
          (item) => item?.delivery_zone === conditions?.delivery_zone
        )?.[0]?.delivery_free ||
        tariffs.filter(
          (item) =>
            item.delivery_zone === this.curr_address_conditions?.default_zone
        )?.[0]?.delivery_free;

      // let filtred_zones = [];
      // for (const key in zones) {
      //   if (Object.hasOwnProperty.call(zones, key)) {
      //     const el = zones[key];
      //     if (el !== undefined) filtred_zones.push(el);
      //   }
      // }
      return { zone: conditions, delivery_free };
      // if (zones.filter((item) => item === house)?.length) {
      //   return zones.filter((item) => item === house)?.[0] || {};
      // } else return false;
    },
    address_affilate_coincidence() {
      const {
        affilate_house_orenburgskiy = "",
        affilate_house_derbishki = "",
        affilate_house_kvartal = "",
      } = this.order_form.fields.street.attrs?.suggestions?.suggest || {};
      const house_inp = this.order_form.fields.house.value;
      let affilates = [
        {
          affilates: affilate_house_orenburgskiy?.split(",") || [],
          affilate: null,
        },
        {
          affilates: affilate_house_derbishki?.split(",") || [],
          affilate: 32,
        },
        {
          affilates: affilate_house_kvartal?.split(",") || [],
          affilate: 51,
        },
      ];
      house_inp;
      // let coincidence_zone = {};
      let conditions = {};
      conditions = affilates.filter(
        (item) => item.affilates.filter((house) => house === house_inp)?.length
      )?.[0] || {
        affilate:
          this.order_form.fields.street.attrs?.suggestions?.suggest
            ?.default_affilate,
      };
      conditions.affilate_name = affilates.filter(
        (item) => item.affilates.filter((house) => house === house_inp)?.length
      )?.[0]?.affilate_name;

      return conditions;
    },
    filtred_affilates() {
      return this.AFFILATES.filter(
        (item) =>
          item?.is_open === "Открыто" && item?.pickup_access === "pickup_access"
      );
    },
    working_time_data() {
      const now = new Date();
      let time_data = {};
      let currentTime = now.getUTCHours() + 3 + ":" + now.getUTCMinutes();
      const {
        expected_delivery_time: { value: deliveryTime } = {},
        time: { value: orderTime } = {},
        type: { value: orderType } = {},
      } = this.order_form.fields || {};

      const { curr_day: { day_start: dayStart, day_end: dayEnd } = {} } =
        this.SITE_SETTINGS;

      const minutesUntilClose =
        this.getFutureDate(dayEnd) - this.getFutureDate(currentTime);

      const minutesAfterOpen =
        this.getFutureDate(currentTime) - this.getFutureDate(dayStart);

      if (minutesUntilClose < 30) {
        this.GLOBAL_NOTIFICATIONS({
          title: "К сожалению мы уже не принимаем заказы",
          text: "Вы можете оставить предзаказ",
          status: "error",
        });
        time_data.until_close = false;
      }
      deliveryTime;
      // if (deliveryTime === "Предзаказ" || deliveryTime === "Ближайшее время")
      //   time_data.qwe = true;
      if (orderType === "Доставка" && minutesAfterOpen > 90)
        time_data.delivery_minutes_after_open = true;
      if (orderType === "Самовывоз" && minutesAfterOpen > 30)
        time_data.pickup_minutes_after_open = true;

      time_data.minutesAfterOpen = minutesAfterOpen;
      time_data.minutesUntilClose = minutesUntilClose;
      time_data.orderTime = orderTime;
      time_data.currentTime = currentTime;
      return time_data;
    },
  },
  async mounted() {
    // this.order_form.fields.time.value = this.pickupTime?.[0]?.name;
    const setPromo = localStorage.getItem("setPromo");
    if (setPromo !== undefined && setPromo !== "undefined") {
      this.order_form.fields.promocode.value = setPromo;
      this.order_form.fields.type.value = "Доставка";
      localStorage.setItem("setPromo", undefined);
      this.checkPromocode();
    }
    this.clone_order_form = JSON.parse(JSON.stringify(this.order_form));
    if (!Array.from(this.CART).length) {
      this.SET_SAVED_CART();
    }
    if (!Array.from(this.AFFILATES).length) {
      let res = await this.GET_AFFILATES();
      console.log(res);
    }
    if (!Array.from(this.ADRESSES).length) {
      let res = await this.GET_ADRESSES();
      this.order_form.fields.street.attrs.suggestions.items = res;
    }
    if (!Array.from(this.PROMOCODES).length) {
      let res = await this.GET_PROMOCODES();
      res;
      this.$nextTick(() => {});
    }

    if (
      this.working_time_data?.minutesAfterOpen < 0 ||
      this.working_time_data?.minutesUntilClose < 30
    ) {
      this.order_form.fields.expected_delivery_time.value = "Предзаказ";
    }
    // fadeFilledAnimate(this.$refs.)
    fadeFilledAnimate;
  },
  created() {},
  watch: {
    order_form: {
      handler() {},
      immediate: true,
      deep: true,
    },
    "order_form.fields.type.value": {
      handler(newVal) {
        this.toggleOrderType(newVal);
        this.checkPromocode();
      },
    },
    "order_form.fields.private_house.value": {
      handler(newVal) {
        if (newVal == "Частный дом") {
          this.order_form.fields.apart.noValidate = true;
          this.order_form.fields.entrance.noValidate = true;
          this.order_form.fields.floor.noValidate = true;
          this.order_form.fields.apart.attrs.disabled = true;
          this.order_form.fields.entrance.attrs.disabled = true;
          this.order_form.fields.floor.attrs.disabled = true;
          this.order_form.fields.apart.value = "";
          this.order_form.fields.entrance.value = "";
          this.order_form.fields.floor.value = "";
          this.order_form.fields.apart.errorBag = [];
          this.order_form.fields.entrance.errorBag = [];
          this.order_form.fields.floor.errorBag = [];
        } else {
          this.order_form.fields.apart.noValidate = false;
          this.order_form.fields.entrance.noValidate = false;
          this.order_form.fields.floor.noValidate = false;
          this.order_form.fields.apart.attrs.disabled = false;
          this.order_form.fields.entrance.attrs.disabled = false;
          this.order_form.fields.floor.attrs.disabled = false;
        }
      },
    },
    "order_form.fields.expected_delivery_time.value": {
      handler(newVal) {
        if (newVal === "Предзаказ") {
          if (this.order_form.fields.time.value?.length <= 0) {
            this.order_form.fields.time.value =
              this.pickupTime?.[1]?.name || this.pickupTime?.[0]?.name;
          }
        }
      },
    },
    CART: {
      handler() {
        // this.checkPromocode();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.soon_as_possible,
.pre_order {
  min-width: 48%;
  min-height: 54px;
  label {
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>
.affilate__row {
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  width: 100%;
  button {
    margin-right: 12px;
    padding: 12px;
    font-size: 18px;
  }
}
.affilate__btn {
  max-width: 49%;
  width: 100%;
}
.cart-info {
  width: 100%;
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  &__wrapper {
    @include container;
    padding: 20px;
  }
  &__input {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    &-icon {
      content: "%";
      padding: 12px 15px;
      margin: 7px 14px 7px 0;
      background-color: $accent;
      border-radius: 10px;
      color: $white;
    }
  }
  &--mobile {
  }
  &__policy {
    text-align: center;
    text-decoration: underline;
    margin: 8px 0;
  }
  &__promocode {
    top: -75px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    width: 85%;
    margin-bottom: 18px;
    &-inp {
      width: 100%;
      padding: 14px 10px;
      border: none;
      @include container;
    }
    &:before {
      content: "%";
      color: $white;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translate(-10px, -50%);
      -ms-transform: translate(-10px, -50%);
      transform: translate(-10px, -50%);
      font-size: 18px;
      border-radius: 10px;
      right: 0;
      width: 30px;
      height: 30px;
      background-color: $accent;
    }
  }
  &__dashes {
    white-space: nowrap;
    -o-text-overflow: clip;
    text-overflow: clip;
    overflow: hidden;
    min-height: 17px;
  }
  &__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: $second-black;
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__description {
  }
}
.confirm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
  &__percent {
    margin-left: 6px;
    color: tomato;
    font-weight: bold;
  }
  &__input {
    @include container;
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 14px;
    font-size: 13px;
    border: none;
    outline: none;
    &::-webkit-input-placeholder {
      color: $second-black;
    }
    &::-moz-placeholder {
      color: $second-black;
    }
    &:-ms-input-placeholder {
      color: $second-black;
    }
    &::-ms-input-placeholder {
      color: $second-black;
    }
    &::placeholder {
      color: $second-black;
    }
    &-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      max-width: 47%;
      width: 100%;
      margin-bottom: 18px;
    }
  }
  &__radio {
    display: none;
    &:checked + label {
      background-color: $accent;
      color: $white;
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
  &__label {
    color: $second-black;
    padding: 10px 8px;
    cursor: pointer;
  }
  &__delivery {
    position: relative;
  }
  &__addresses {
    position: absolute;
    top: 40%;
    @include container;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 1;
    width: 100%;
    max-height: 175px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 14px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $unactive_accent; /* color of the tracking area */
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid $unactive_accent; /* creates padding around scroll thumb */
    }
  }
  &__address {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid $accent;
    }
  }
}
.confirm-enter-from,
.confirm-leave-to {
  opacity: 0;
}

.confirm-enter-active,
.confirm-leave-active {
  -webkit-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
.send-form-btn {
  border-radius: 10px;
  background-color: $accent;
  color: $white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 76px;
  padding: 15px;
  font-family: roboto, sans-serif;
  width: 100%;
  bottom: 64px;
  transition: 0.3s ease;
  margin-top: 12px;
  cursor: pointer;
  p {
    text-align: left;
  }
  p:nth-child(1) {
    font-size: 16px;
    margin-bottom: 6px;
  }
  p:nth-child(2) {
    font-size: 18px;
    font-weight: bold;
  }
  svg {
    path {
      stroke: #fff;
    }
  }
}
// .unactive {
//   background-color: #f5f5f7;
//   color: #6b6b6b;
//   svg {
//     path {
//       stroke: #6b6b6b;
//     }
//   }
// }

.cart {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 10px;
  // .cart__back

  &__back {
    width: 100%;
    margin-bottom: 20px;
  }

  // .cart__back-title

  &__back-title {
  }

  // .cart__data

  &__data {
    width: 65%;
    padding-right: 20px;
  }

  // .cart__confirm

  &__confirm {
    width: 35%;
  }

  // .cart__order

  &__order {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.cart-back-btn {
}
.order {
  // .order__form

  &__form {
  }

  // .order__form-input

  &__form-input {
    margin-bottom: 20px;
  }

  // .order__form-wrapper

  &__form-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .order__form-input {
      width: 23%;
      &:first-child {
        width: 100%;
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}
.registration {
  // .registration__form-select

  &__form-select {
  }

  // .registration__form-employer

  &__form-employer {
  }
}
.cart-info {
  // .cart-info__input

  &__input {
  }

  // .cart-info__input-icon

  &__input-icon {
  }

  // .cart-info__wrapper

  &__wrapper {
  }

  // .cart-info__list

  &__list {
  }

  // .cart-info__item

  &__item {
  }

  // .cart-info__description

  &__description {
  }

  // .cart-info__dashes

  &__dashes {
  }

  // .cart-info__policy

  &__policy {
  }
}
.send-form-btn {
}
.name,
.street,
.comment,
.time {
  width: 100%;
}
.phone,
.cutlery,
.pickup,
.delivery,
.card,
.cash {
  width: 48%;
}
.order__form-select {
  height: 44px;
}
.time {
  margin-bottom: 20px;
}
.promocode {
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .cart {
    // .cart__back

    &__back {
    }

    // .cart__back-title

    &__back-title {
    }

    // .cart__data

    &__data {
      width: 45%;
    }

    // .cart__confirm

    &__confirm {
      width: 55%;
    }

    // .cart__order

    &__order {
    }
  }
}
@media (max-width: 768px) {
  .cart {
    justify-content: center;
    // .cart__back

    &__back {
    }

    // .cart__back-title

    &__back-title {
    }

    // .cart__data

    &__data {
      width: 100%;
      padding-right: 0;
    }

    // .cart__confirm

    &__confirm {
      max-width: 441px;
      margin-top: 24px;
      width: 100%;
    }

    // .cart__order

    &__order {
    }
  }
}
</style>
